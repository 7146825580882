<template>
  <v-container fluid>
    <v-row>
      <visualizacao-acessos-filtros
        v-model="filtros"
        :carregando="carregando"
        @buscar="buscarAcessos(true)"
      />
    </v-row>

    <v-row>
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="linhas"
            :items-per-page="10"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="buscarAcessos"
          >
            <template v-slot:[`item.usuario.nome_usuario`]="{ item }">
              <div class="tables" @click="buscarDetalhesUsuario(item.usuario)">
                {{ item.usuario ? item.usuario.nome_usuario : null }}
              </div>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <dialog-usuario
      :mostrar="mostrarDialogUsuario"
      :usuario="detalhesUsuario"
      :empresaInfo="detalhesEmpresa"
      @fechar="mostrarDialogUsuario = false"
    />
  </v-container>
</template>

<script>
import visualizacaoAcessos from "@/api/acessos";
import formatarData from "@/utils/formatar-data-input";
import empresa from "@/api/empresas";
import dayjs from "dayjs";

export default {
  components: {
    DialogUsuario: () => import("@/components/dialog/usuario/DialogUsuario"),
    VisualizacaoAcessosFiltros: () =>
      import(
        "@/views/pages/relatorios/visualizacao-acessos/VisualizacaoAcessosFiltros"
      )
  },

  data() {
    return {
      linhas: [],
      carregando: false,
      carregandoDetalhesUsuario: false,
      menuDataInicio: false,
      menuDataFim: false,
      dataInicioFormatada: null,
      dataFimFormatada: null,
      filtros: {
        data_inicio: dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        data_fim: dayjs()
          .endOf("month")
          .format("YYYY-MM-DD")
      },
      cabecalho: [
        { text: "Nome Usuário", value: "usuario.nome_usuario" },
        { text: "Data", value: "data_acesso" },
        { text: "Endereço Ip", value: "endereco_ip" },
        { text: "Informações", value: "informacoes" },
        { text: "Metodo", value: "metodo" },
        { text: "Código de resposta", value: "response_code" },
        { text: "Url", value: "url" }
      ],
      paginacao: {},
      totalRegistros: null,
      mostrarDialogUsuario: false,
      detalhesUsuario: null,
      detalhesEmpresa: null,
      filtros: {
        campos: {
          data_inicio: "",
          data_fim: "",
          hora_inicio: "",
          hora_fim: ""
        }
      }
    };
  },

  watch: {
    "filtros.data_inicio": {
      immediate: true,
      handler(data) {
        this.dataInicioFormatada = formatarData.ptBr(data);
      }
    },
    "filtros.data_fim": {
      immediate: true,
      handler(data) {
        this.dataFimFormatada = formatarData.ptBr(data);
      }
    }
  },

  methods: {
    async buscarAcessos() {
      try {
        this.carregando = true;

        const parametros = { ...this.filtros.campos };

        parametros.por_pagina = this.paginacao.itemsPerPage;
        parametros.pagina = this.paginacao.page;

        const resposta = await visualizacaoAcessos.visualizacaoAcessos(
          parametros
        );

        resposta.data.data.map(item => {
          item.data_acesso = formatarData.formatoTZ(item.data_acesso);
        });

        this.linhas = resposta.data.data;

        this.totalPaginas = resposta.data.meta.last_page;
        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
    async buscarDetalhesUsuario(dados) {
      console.log(dados, "DEDOS");
      try {

        const empresaId = dados.empresa_id;

        const empresaInfo = await empresa.buscar(empresaId);


        this.detalhesEmpresa = empresaInfo
        this.detalhesUsuario = dados;

        this.mostrarDialogUsuario = true;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoDetalhesLeitura = false;
      }
    }
  }
};
</script>

<style>

.tables {
  cursor: pointer;
  background-color: #e6e1e1;
  border-radius: 2px;
  padding: 0 5px;
}
.recorte-placa {
  width: 100%;
  cursor: pointer;
}
.placasCorrigidas
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:first-child {
  font-size: 13px;
}

@media screen and (min-width: 768px) {
  .recorte-placa {
    width: 45% !important;
  }
}
</style>
